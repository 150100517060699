// src/pages/Home.js
import { Button, Banner } from 'flowbite-react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom';
import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline'
import { FaBookOpen } from "react-icons/fa";
import { HiArrowRight, HiX } from "react-icons/hi";
import HeroSlider from '../components/HeroSlider';
import ResourceHeader from '../components/resources';
   

const stats = [
    { id: 1, name: 'Years of Cybersecurity Expertise', value: '10+' },
    { id: 2, name: 'Vulnerabilities Identified and Resolved', value: '500+' },
    { id: 3, name: 'Threat Detection Accuracy', value: '99.9%' },
    { id: 4, name: 'Security Monitoring', value: '24/7' },
  ]

  
  

const Home = () => {
    
  return (
    <div>
    <Helmet>
        <title>Infoleet – Cybersecurity Solutions</title>
        <meta name="description" content="Infoleet provides industry-leading cybersecurity services, ensuring your business is protected from evolving digital threats." />
    </Helmet>
    <HeroSlider  />
      {/* Hero Section */}
     {/*  <section className="relative text-white h-[45vh] flex items-center justify-center">
       <div className="absolute inset-0 bg-slate-900 bg-blend-overlay bg-cover bg-center" style={{ backgroundImage: 'url("/image2.jpg")' }}></div>
       
        <div className="relative z-10 text-center">
          <h1 className="text-4xl font-bold mb-4">Protecting Your Digital Future</h1>
          <p className="text-lg">We provide world-class cybersecurity solutions to safeguard your business.</p>
        </div>
      </section> */}

      {/* About Section */}
        <div className="bg-slate-50 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-12">
            {/* Left Side - Image or Video */}
            <div className="w-full lg:w-1/2">
                <img
                src="/about.jpg"
                alt="About Infoleet"
                className="w-90 h-auto rounded-lg"
                />
                {/* If you want to use a video instead of an image, replace the <img> tag with <video> */}
            </div>

            {/* Right Side - About Us Text */}
            <div className="w-full lg:w-1/2 text-grey-950">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">About Infoleet</h2>
                <p className="mt-4 mb-2 text-lg leading-8 text-gray-900 text-justify">
                At Infoleet, we are a UK-based cybersecurity company with over a decade of proven expertise in securing digital environments. Our team comprises seasoned professionals who are not only industry veterans but also hold globally recognized certifications, showcasing their excellence and commitment to the highest standards of security. From manual testing to sophisticated automated processes, we deliver a comprehensive suite of cybersecurity services tailored to protect your business from evolving cyber threats.
                </p>
                {/* <p className="mt-4 text-lg leading-8 text-gray-300 text-justify">
                Our experience spans industries and global markets, allowing us to understand the unique challenges businesses face in today’s interconnected world. Whether you’re looking to enhance your security protocols, conduct thorough penetration testing, or achieve compliance with global security standards, Infoleet is your trusted partner.
                </p> */}
                <a className='text-indigo-700 hover:text-indigo-800' href="/about">Learn more <span aria-hidden="true">&rarr;</span></a>
            </div>
            </div>

            {/* Optional Stats Section */}
            <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col bg-slate-800 p-8">
                <dt className="text-sm font-semibold leading-6 text-gray-50">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-100">{stat.value}</dd>
                </div>
            ))}
            </dl>
        </div>
        </div>


      {/* Services Section */}
      <section className="bg-slate-50 py-1">
        <div className="bg-white py-1 sm:py-1">
        <div className="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
            <a className="text-base/7 font-semibold text-indigo-600" href="/services">Learn more <span aria-hidden="true">&rarr;</span></a>
            <p className="mt-2 max-w-lg text-pretty text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl">
            Our Services 
            </p>
            <div className="mt-10 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2">
            <div className="relative lg:col-span-3">
                <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)] lg:rounded-tl-[calc(2rem+1px)]">
                <img
                    alt="Web Application Security service by Infoleet"
                    src="/application_security1.png"
                    className="h-80 object-cover object-left"
                />
                <div className="p-10 pt-4">
                    <a className="text-sm/4 font-semibold text-indigo-600" href='/services/web-application-security'>Learn more <span aria-hidden="true">&rarr;</span></a>
                    <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Web Application Security</p>
                    <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    Comprehensive testing of your web applications to uncover security vulnerabilities such as SQL injection, cross-site scripting (XSS), insecure authentication and other owasp top 10. Our experts use both manual techniques and automated tools to identify weaknesses in your web infrastructure.
                    </p>
                </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]" />
            </div>
            <div className="relative lg:col-span-3">
                <div className="absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-tr-[calc(2rem+1px)]">
                <img
                    alt=""
                    src="/mobile-application-security.png"
                    className="h-80 object-cover object-left lg:object-right"
                />
                <div className="p-10 pt-4">
                    <a className="text-sm/4 font-semibold text-indigo-600" href='/services/mobile-application-security'>Learn More <span aria-hidden="true">&rarr;</span></a>
                    <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Mobile Application Security</p>
                    <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    Ensure the safety of your mobile applications with advanced security solutions. We identify vulnerabilities and protect your app from threats such as malware, data breaches, and unauthorized access, safeguarding both your users and their data.
                    </p>
                </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-tr-[2rem]" />
            </div>
            <div className="relative lg:col-span-2">
                <div className="absolute inset-px rounded-lg bg-white lg:rounded-bl-[2rem]" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-bl-[calc(2rem+1px)]">
                <img
                    alt=""
                    src="/cloud-security2.png"
                    className="h-80 object-cover object-center"
                />
                <div className="p-10 pt-4">
                    <a className="text-sm/4 font-semibold text-indigo-600" href='/services/cloud-security-hardening'>Learn more <span aria-hidden="true">&rarr;</span></a>
                    <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Cloud Security</p>
                    <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    Keep your cloud infrastructure secure with tailored security measures designed to protect sensitive data, applications, and services. Our cloud security solutions defend against threats, ensuring compliance and secure access in your cloud environments.
                    </p>
                </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-bl-[2rem]" />
            </div>
            <div className="relative lg:col-span-2">
                <div className="absolute inset-px rounded-lg bg-white" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                <img
                    alt=""
                    src="/redteaming1.png"
                    className="h-80 object-cover object-center"
                />
                <div className="p-10 pt-4">
                    <a className="text-sm/4 font-semibold text-indigo-600" href='/services/red-team-assessment'>Learn more <span aria-hidden="true">&rarr;</span></a>
                    <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Red Team Assessments</p>
                    <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    A full-scope adversarial simulation designed to test your security measures and incident response capabilities. Our Red Team works covertly to mimic advanced persistent threats, targeting your organization's crown jewels to expose weaknesses.
                    </p>
                </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5" />
            </div>
            <div className="relative lg:col-span-2">
                <div className="absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-br-[calc(2rem+1px)]">
                <img
                    alt=""
                    src="threat.png"
                    className="h-80 object-cover object-right"
                />
                <div className="p-10 pt-4">
                    <a className="text-sm/4 font-semibold text-indigo-600" href='/services/threat-intelligence-as-service'>Learn more <span aria-hidden="true">&rarr;</span></a>
                    <p className="mt-2 text-lg/7 font-medium tracking-tight text-gray-950">Threat Intelligence as a Service</p>
                    <p className="mt-2 max-w-lg text-sm/6 text-gray-600">
                    Stay ahead of emerging cyber threats with real-time intelligence. Our Threat Intelligence as a Service provides actionable insights into global threat actors, attack trends, and vulnerabilities, helping you proactively protect your organization from potential attacks.
                    </p>
                </div>
                </div>
                <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-br-[2rem]" />
            </div>
            </div>
            <Banner>
            <div className="flex w-full flex-col justify-between p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
                <div className="mb-4 md:mb-0 md:mr-4">
                <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white"> </h2>
                <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                    
                </p>
                </div>
                <div className="flex shrink-0 items-center">
                <a
                    href="/services"
                    className="mr-3 inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white px-3 py-2 text-xs font-medium text-gray-900 hover:bg-gray-900 hover:text-slate-50 focus:z-10 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                >
                    <FaBookOpen className="mr-2 h-4 w-4" />
                    Check our full list of services
                </a>
                <a
                    href="/contact"
                    className="mr-2 inline-flex items-center justify-center rounded-lg bg-indigo-700 px-3 py-2 text-xs font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-4 focus:ring-cyan-300 dark:bg-cyan-600 dark:hover:bg-cyan-700 dark:focus:ring-cyan-800"
                >
                    Get a quote
                    <HiArrowRight className="ml-2 h-4 w-4" />
                </a>
                {/* <Banner.CollapseButton color="gray" className="border-0 bg-transparent text-gray-500 dark:text-gray-400">
                    <HiX className="h-4 w-4" />
                </Banner.CollapseButton> */}
                </div>
            </div>
            </Banner>
        </div>
        
        </div>
      </section>
     
     {/* Blog section */}
     <section>
     <ResourceHeader />
     
     </section>
     
     {/* trusted section */}
      <section>
        <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
            <div className="mx-auto w-full max-w-xl lg:mx-0">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Trusted Technologies & Certifications</h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                At Infoleet, we harness the power of industry-leading tools, technologies, and global certifications to deliver top-notch cybersecurity solutions. Our expertise is built on trusted, reliable platforms that secure businesses worldwide.
                </p>
                <div className="mt-8 flex items-center gap-x-6">
                <a
                    href="/contact"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Get a quote
                </a>
                <a href="/contact" className="text-sm font-semibold text-gray-900">
                    Contact us <span aria-hidden="true">&rarr;</span>
                </a>
                </div>
            </div>
            <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
                <img
                alt="CISA from ISACA"
                src="/cisa.png"
                width={200}
                height={48}
                className="max-h-18 w-50 object-cover object-left"
                />
                <img
                alt="Offensive security certified penetration tester"
                src="/oscp.png"
                width={110}
                height={70}
                className="max-h-18 w-30 object-cover object-left"
                />
                <img
                alt="Comptia Security+"
                src="/CompTIA_Security_2Bce.png"
                width={120}
                height={64}
                className="max-h-18 w-25 object-cover object-left"
                />
                <img
                alt="CEH"
                src="/ceh.png"
                width={150}
                height={64}
                className="max-h-16 w-22 object-cover object-left"
                />
                <img
                alt="ECPPT"
                src="/ecppt.png"
                width={140}
                height={64}
                className="max-h-18 w-30 object-contain object-left"
                />
                <img
                alt="Certified Red Team Operator"
                src="/certified_red_team_operator.png"
                width={120}
                height={64}
                className="max-h-18 w-30 object-contain object-left"
                />
            </div>
            </div>
        </div>
        </div>
      </section>

      {/* Newsletter */}
      {/* <section>
        <div className="relative isolate overflow-hidden bg-gray-900 py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="max-w-xl lg:max-w-lg">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Subscribe to our newsletter.</h2>
                <p className="mt-4 text-lg leading-8 text-gray-300">
                Nostrud amet eu ullamco nisi aute in ad minim nostrud adipisicing velit quis. Duis tempor incididunt
                dolore.
                </p>
                <div className="mt-6 flex max-w-md gap-x-4">
                <label htmlFor="email-address" className="sr-only">
                    Email address
                </label>
                <input
                    id="email-address"
                    name="email"
                    type="email"
                    required
                    placeholder="Enter your email"
                    autoComplete="email"
                    className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                />
                <button
                    type="submit"
                    className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                    Subscribe
                </button>
                </div>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                <div className="flex flex-col items-start">
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <CalendarDaysIcon aria-hidden="true" className="h-6 w-6 text-white" />
                </div>
                <dt className="mt-4 font-semibold text-white">Weekly articles</dt>
                <dd className="mt-2 leading-7 text-gray-400">
                    Non laboris consequat cupidatat laborum magna. Eiusmod non irure cupidatat duis commodo amet.
                </dd>
                </div>
                <div className="flex flex-col items-start">
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                    <HandRaisedIcon aria-hidden="true" className="h-6 w-6 text-white" />
                </div>
                <dt className="mt-4 font-semibold text-white">No spam</dt>
                <dd className="mt-2 leading-7 text-gray-400">
                    Officia excepteur ullamco ut sint duis proident non adipisicing. Voluptate incididunt anim.
                </dd>
                </div>
            </dl>
            </div>
        </div>
        <div aria-hidden="true" className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6">
            <div
            style={{
                clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
            />
        </div>
        </div>
      </section> */}
    </div>
  );
};

export default Home;
