import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { HomeIcon } from '@heroicons/react/24/outline';
import services from '../components/services/servicesData';  // Import your services array

const Services = () => {
  const [selectedService, setSelectedService] = useState(services[0]); // Default to the first service

  return (
    <>
      <Helmet>
        <title>{selectedService.name} - Infoleet</title>
        <meta name="description" content={`Learn more about ${selectedService.name}`} />
      </Helmet>

      <div className="flex min-h-screen">
        {/* Sidebar */}
        <div className="hidden lg:flex lg:flex-col lg:w-72 bg-white min-h-screen">
          <div className="flex flex-col gap-y-6 py-10 px-6">
            <h1 className="text-lg font-semibold">Our Services</h1>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                {services.map((service) => (
                  <li key={service.id}>
                    <Link
                      to={`/services/${service.id}`}  // Dynamic Link to Service Detail Page
                      className={`${
                        selectedService.name === service.name
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600'
                      } group flex gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6`}
                    >
                      {service.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>

        {/* Main Content */}
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <main className="flex-grow p-6">
            {/* Breadcrumb */}
            <nav aria-label="Breadcrumb" className="flex">
              <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
                <li className="flex">
                  <div className="flex items-center">
                    <Link to="/" className="text-gray-400 hover:text-gray-500">
                      <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                      <span className="sr-only">Home</span>
                    </Link>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex items-center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      aria-hidden="true"
                      className="h-full w-6 flex-shrink-0 text-gray-200"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <Link
                      to="/services"
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      Services
                    </Link>
                  </div>
                </li>
                <li className="flex">
                <div className="flex items-center">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    aria-hidden="true"
                    className="h-full w-6 flex-shrink-0 text-gray-200"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <span
                    className="ml-4 text-sm font-medium text-gray-700"
                    aria-current="page"
                  >
                    {selectedService.name}
                  </span>
                </div>
              </li>
              </ol>
            </nav>

            {/* Render Selected Service */}
            <div
              className="bg-fixed bg-no-repeat bg-center p-6 m-4 rounded-lg shadow-lg text-white"
              style={{ backgroundImage: `url(${selectedService.image})` }}
            >
              {selectedService.description}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Services;
