import React, { useState, useEffect } from 'react';

// Sample images with captions and sub-captions
const images = [
  {
    src: '/image1.jpg',
    caption: 'Secure Your Digital Future',
    subCaption: 'Cybersecurity solutions tailored for your business.',
  },
  {
    src: '/image2.jpg',
    caption: 'Stay Ahead of Cyber Threats',
    subCaption: 'Proactive measures to protect your assets.',
  },
  {
    src: '/image3.jpg',
    caption: 'Fortify Your Business Security',
    subCaption: 'End-to-end protection from cyber attacks.',
  },
];

const HeroSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto change slides every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  return (
    <div className="relative w-full h-[45vh] overflow-hidden">
      <div
        className="flex transition-transform ease-in-out duration-700"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div key={index} className="w-full h-full flex-shrink-0 relative">
            <img
              src={image.src}
              alt={`Slide ${index + 1}`}
              className="w-full h-[45vh] object-cover"
            />

            {/* Overlay Text */}
            <div className="absolute inset-0 bg-slate-900 bg-opacity-70 flex flex-col items-center justify-center text-center text-white">
              <div className="px-4 py-2 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl">
                  <h2 className="text-3xl font-bold tracking-tight text-slate-50 sm:text-4xl">
                    {image.caption}
                    <br />
                    Get started with Infoleet today.
                  </h2>
                  <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-slate-100">
                    {image.subCaption}
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6">
                    <a
                      href="/contact"
                      className="rounded-md bg-indigo-600 hover:bg-indigo-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-cyan-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Get started
                    </a>
                    <a
                      href="/services"
                      className="text-sm font-semibold leading-6 text-slate-50"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation buttons */}
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-cyan-800/5 text-white/5 p-2 rounded-full"
        onClick={prevSlide}
      >
        &#10094;
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-cyan-800/5 text-white/5 p-2 rounded-full"
        onClick={nextSlide}
      >
        &#10095;
      </button>

      {/* Indicators */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              currentIndex === index ? 'bg-blue-500' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroSlider;
