import WebAppSecurity from './WebAppSecurity';
import MobileAppSecurity from './MobileAppSecurity';
import CloudSecurity from './CloudSecurity';
import RedTeamAssestment from './RedTeamAssestment';
import ThreatIntelligenceService from './ThreatIntelligence';

const services = [
  {
    id: 'web-application-security',
    name: 'Web Application Security',
    description: <WebAppSecurity />,
    sampleReportUrl: 'https://example.com/sample-web-app-security-report.pdf',
    image: '/application_security1.png',
  },
  {
    id: 'mobile-application-security',
    name: 'Mobile Application Security',
    description: <MobileAppSecurity />,
    sampleReportUrl: 'https://example.com/sample-mobile-app-security-report.pdf',
    image: '/mobile-application-security.png',
  },
  {
    id: 'cloud-security-hardening',
    name: 'Cloud Security & Hardening',
    description: <CloudSecurity />,
    sampleReportUrl: 'https://example.com/sample-cloud-security-report.pdf',
    image: '/cloud-security2.png',
  },
  {
    id: 'red-team-assessment',
    name: 'Red Team Assessment',
    description: <RedTeamAssestment />,
    sampleReportUrl: 'https://example.com/sample-red-team-report.pdf',
    image: '/redteaming1.png',
  },
  {
    id: 'threat-intelligence-as-service',
    name: 'Threat Intelligence as Service (TIaaS)',
    description: <ThreatIntelligenceService />,
    sampleReportUrl: 'https://example.com/sample-threat-intelligence-report.pdf',
    image: '/threat.png',
  },
];

export default services;
