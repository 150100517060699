import React from 'react';
import { ChatBubbleOvalLeftEllipsisIcon, HeartIcon, PencilSquareIcon, TrashIcon, PuzzlePieceIcon } from '@heroicons/react/24/outline';
import { FaMagnifyingGlassChart } from "react-icons/fa6";
import { BiAnalyse } from "react-icons/bi";
import { GiFirewall } from "react-icons/gi";

const CloudSecurity = () => {
  return (
    <div className="bg-transparent text-gray-800">
      {/* Header Section */}
      <section className="text-left py-10 bg-white bg-opacity-80">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h1 className="text-pretty text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Redteam Assessments</h1>
        <p className="text-lg text-justify max-w-5xl py-10 mx-auto">
        At Infoleet, we provide comprehensive Red Team Assessment services designed to test your organization’s defense mechanisms through simulated, real-world cyberattacks. Our goal is to help you uncover hidden vulnerabilities, assess your security posture, and provide actionable recommendations to enhance your cybersecurity resilience.
        </p>
        </div>
      </section>


        <div className="bg-white bg-opacity-80 py-15 sm:py-18">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <h2 className="text-pretty text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Service Breakdown
            </h2>
            <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
                {breakdown.map((breakdown) => (
                <div key={breakdown.title}>
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="mb-2 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                        <breakdown.icon aria-hidden="true" className="h-6 w-6 text-white" />
                    </div>
                    {breakdown.title}
                    </dt>
                    <dd className="mt-1 text-base leading-7 text-gray-600">{breakdown.description}</dd>
                </div>
                ))}
            </dl>
            </div>
        </div>
        </div>

      {/* Key Features Section */}
      <section className="py-20 bg-white bg-opacity-80">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-pretty text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Key Features</h2>
        <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-10 max-w-6xl mx-auto">
          {features.map((feature, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-bold mb-4">{feature.title}</h3>
              <p className="text-gray-600 text-left">{feature.description}</p>
            </div>
          ))}
        </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-4 bg-white bg-opacity-80">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-pretty text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">Why Choose Infoleet?</h2>
        <p>Unlike automated-only solutions, we combine expert manual testing with cutting-edge automation, providing you with a 360-degree view of your app's security. Our certified professionals, equipped with industry-recognized tools like NowSecure and Astra, ensure your app is protected against current and emerging threats.</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto py-10">
          {reasons.map((reason, index) => (
            <div key={index} className="bg-white shadow-lg rounded-lg p-6">
              <h3 className="text-2xl font-bold mb-4">{reason.title}</h3>
              <p className="text-gray-600">{reason.description}</p>
            </div>
          ))}
        </div>
        </div>
      </section>
    </div>
  );
};

const breakdown = [
    {
        title: "Full-Spectrum Attack Simulation",
        description: "Our Red Team assesses your organization’s ability to detect, respond, and mitigate threats by simulating advanced cyberattacks, including social engineering, network breaches, and physical security exploits. We employ the latest adversary techniques seen in real-world cybercrime, such as lateral movement, privilege escalation, and exfiltration of sensitive data​.",
        icon: PuzzlePieceIcon,
    },
    {
        title: "Tailored Engagements", 
        description: "We customize each assessment based on your organization’s needs, whether it's testing for ransomware preparedness, insider threats, or advanced persistent threat (APT) scenarios. We work with you to define objectives that align with your security goals.",
        icon: FaMagnifyingGlassChart,
    },
    {
        title:"Advanced Threat Detection and Response", 
        description: "Using AI-driven threat detection and machine learning, we identify and respond to zero-day threats, phishing attempts, and other attacks targeting your cloud infrastructure. Our automated responses ensure quick action, minimizing potential damage.",
        icon: BiAnalyse,
    },
    {
      title:"Post-Assessment Reporting", 
      description: "After the Red Team exercise, we provide a detailed report that outlines the vulnerabilities discovered, attack methods used, and their potential impacts. Our report includes clear, actionable steps for improving your security defenses.",
      icon: GiFirewall,
  }
]

const features = [
    {
       title: "No-Holds-Barred Approach", description: "We combine open-source intelligence, social engineering, and the expertise of our certified security professionals to exploit your defenses as a real attacker would.",

    },
    {
        title:"Real-World Tactics, Techniques, and Procedures (TTPs)", description: "Our Red Team mirrors the behaviors of sophisticated adversaries, using the MITRE ATT&CK framework to ensure we test your systems against the latest cyberattack strategies."
    },
    {
      title:"Turnaround Time", description: "Depending on the scope, our Red Team engagements typically last 4-6 weeks, with ongoing monitoring and follow-up tests available for continuous security improvement."
    },
    
]

// Data for Key Features
const features2 = [
  {
    title: 'Application Vulnerability Assessment',
    description:
      'We identify weaknesses that could expose your web applications to cyberattacks. Our assessments cover vulnerabilities like SQL Injection, Cross-Site Scripting (XSS), and Insecure Authentication Methods. Using a combination of manual code reviews and automated scanning tools, such as Burp Suite, Netsparker, and OWASP ZAP, we ensure that no vulnerability is left unchecked. Our team goes beyond surface-level scans. We perform a deep dive into application logic to find vulnerabilities that automated tools miss, giving you complete peace of mind.',
  },
  {
    title: 'Penetration Testing',
    description:
      "Our expert penetration testers simulate real-world attacks to evaluate your application's defense mechanisms. We follow industry standards like OWASP, PTES, and NIST frameworks to execute comprehensive tests. We assess not just the application's front end but also the back end, APIs, and databases for potential threats. We use custom-built attack scripts for testing, tailored specifically to your application. This approach provides insights into unique attack vectors that aren’t part of conventional automated tools, ensuring that even zero-day vulnerabilities are caught.",
  },
  {
    title: 'Security Code Review',
    description:
      'A secure web application starts with secure code. We offer in-depth code reviews to find issues like buffer overflows, unvalidated input, and weak encryption practices. Our review process uses tools like SonarQube, Checkmarx, and Veracode to analyze your codebase and detect any vulnerabilities. Beyond automated tools, our manual security code review by expert penetration testers uncovers vulnerabilities that only human eyes can detect, ensuring airtight code security.',
  },
  {
    title: 'Web Application Firewall (WAF)',
    description:
      "A Web Application Firewall (WAF) provides critical protection against cyber threats like DDoS attacks, Cross-Site Request Forgery (CSRF), and malware injections. We configure and manage WAFs using industry-leading solutions like Cloudflare WAF, Imperva, and AWS WAF to filter and monitor HTTP traffic, blocking malicious requests before they reach your application. Our customized WAF rulesets are designed based on your application's unique requirements, providing protection from even the most advanced attacks while ensuring minimal impact on application performance.",
  },
  {
    title: 'Authentication and Access Control',
    description:
      'We enhance your application’s authentication mechanisms to prevent unauthorized access. This includes implementing multi-factor authentication (MFA), OAuth 2.0, and secure session management. We also enforce granular access controls to restrict user permissions based on roles and privileges. Our team goes a step further by implementing context-aware access control, which dynamically adjusts user permissions based on factors like geographic location and time of access, offering unmatched security flexibility.',
  },
  {
    title: 'Data Protection and Encryption',
    description:
      'Data breaches can cause irreparable damage to your business. We ensure that your sensitive data is protected using encryption protocols like AES-256 and TLS 1.3. Whether data is at rest or in transit, we secure it using HSMs (Hardware Security Modules) and SSL/TLS certificates, preventing any unauthorized access or interception. We implement Perfect Forward Secrecy (PFS) for encryption, ensuring that even if an attacker manages to gain access to session keys, they can’t decrypt past sessions.',
  },
  {
    title: 'Compliance and Regulatory Alignment',
    description:
      'We align your web applications with international compliance standards, including GDPR, PCI-DSS, HIPAA, and ISO 27001. Our audits and assessments ensure that your applications meet regulatory requirements, avoiding penalties and safeguarding your business reputation. We offer automated compliance audits, reducing the time and effort required to meet regulatory standards, so you can focus on business growth while staying secure.',
  },
  {
    title: 'Continuous Monitoring and Threat Intelligence',
    description:
      'With cyber threats evolving constantly, staying one step ahead is critical. Our continuous monitoring solutions leverage SIEM (Security Information and Event Management) tools such as Splunk, AlienVault, and ELK Stack to provide real-time alerts on suspicious activity. We integrate threat intelligence feeds to detect and respond to new threats proactively. We utilize AI-powered threat detection to identify abnormal patterns in real-time, enabling us to thwart sophisticated attacks before they compromise your web application.',
  },
  {
    title: 'API Security Testing',
    description:
      'APIs are a common target for attackers. We offer API security testing using Postman, OWASP ZAP, and SoapUI to ensure that your APIs are protected against threats like API injection attacks, data exposure, and broken authentication. Our comprehensive API fuzzing tests send unexpected inputs to your API, revealing weaknesses that traditional testing methods miss, ensuring that every aspect of your application is secure.',
  },
  {
    title: 'Incident Response and Recovery',
    description:
      'In the event of a security breach, swift action is crucial to minimize damage. Our Incident Response Team (IRT) is on hand 24/7 to mitigate the impact of attacks, restore services, and conduct thorough forensic analysis. We help you recover quickly while strengthening your defenses to prevent future incidents. We offer Post-Breach Hardening, identifying not just the attack vector but also related vulnerabilities that may not have been exploited, ensuring your web application is more resilient after the recovery process.',
  },
];

// Data for Why Choose Us
const reasons = [
  {
    title: 'Proactive Defense',
    description:
      'Our combination of manual testing, automated tools, and continuous monitoring ensures your web applications stay secure.',
  },
  {
    title: 'Tailored Solutions',
    description:
      'We custom-build security solutions to match the unique structure and needs of your web applications, ensuring comprehensive protection.',
  },
  {
    title: 'Expert Team',
    description:
      'With over a decade of experience, our globally certified professionals bring unmatched expertise to fortify your web applications.',
  },
  {
    title: 'Cost-Effective',
    description:
      'We offer security services that are not only top-tier but also budget-friendly, making enterprise-level protection accessible to all.',
  },
];

export default CloudSecurity;
