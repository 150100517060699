import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import services from '../components/services/servicesData';  // Import your services array
import { HomeIcon } from '@heroicons/react/24/outline';

const ServiceDetail = () => {
  const { serviceId } = useParams();  // Get the dynamic part of the URL
  const service = services.find((s) => s.id === serviceId);  // Find the service by ID

  if (!service) {
    return <p>Service not found!</p>;
  }

  return (
    <div className="container mx-auto px-6 py-12">
      {/* Breadcrumb */}
      <nav aria-label="Breadcrumb" className="flex">
              <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
                <li className="flex">
                  <div className="flex items-center">
                    <Link to="/" className="text-gray-400 hover:text-gray-500">
                      <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                      <span className="sr-only">Home</span>
                    </Link>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex items-center">
                    <svg
                      fill="currentColor"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      aria-hidden="true"
                      className="h-full w-6 flex-shrink-0 text-gray-200"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <Link
                      to="/services"
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                    >
                      Services
                    </Link>
                  </div>
                </li>
                <li className="flex">
                <div className="flex items-center">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    aria-hidden="true"
                    className="h-full w-6 flex-shrink-0 text-gray-200"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <span
                    className="ml-4 text-sm font-medium text-gray-700"
                    aria-current="page"
                  >
                    {service.name}
                  </span>
                </div>
              </li>
              </ol>
            </nav>
      {/* <h1 className="text-3xl font-bold text-indigo-600 mb-4">{service.name}</h1> */}
      <div className="bg-fixed bg-no-repeat bg-center p-6 m-4 rounded-lg shadow-lg text-white"
           style={{ backgroundImage: `url(${service.image})` }}>
        {service.description}
      
        <div className="mt-8 flex items-center gap-x-6">
                <a
                    href="/contact"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    View Sample Report
                </a>
                <a href="/contact" className="rounded-md text-sm font-semibold py-2 px-2 bg-slate-100 bg-opacity-50 text-gray-800">
                    Get a quote <span aria-hidden="true">&rarr;</span>
                </a>
        </div>
      {/* <Link
        href={service.sampleReportUrl}
      >
        <button className="text-slate-50 bg-indigo-700 py-3 px-6 hover:bg-indigo-800 mt-6 text-lg">View Sample Report</button>
      </Link> */}
      </div>
    </div>
  );
};

export default ServiceDetail;
